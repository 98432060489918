<template>
    <div class="c-numeric-input-page">
        <numpad-input :beacons="localPageData.Beacons || []"></numpad-input>
    </div>
</template>

<script>
import pageMixin from '../../mixins/page'
import NumpadInput from '../NumpadInput.vue'

export default {
  mixins: [pageMixin],
  components: {
    NumpadInput
  }
}
</script>
